<template>
  <eagle-list
    :list-key="listKey"
  ></eagle-list>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './productListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'product-list',
    meta: {},
  }),
  methods: {
    async beforeIndex() {
      await Promise.all([
        this.loadCategories(),
      ])
    },
    async loadCategories() {
      let result = null
      try {
        result = await this.$api.collection.productCategoryApi.flatTree()
      } catch (error) {
        console.warn(error)
      }

      this.meta.categories = result.nodes
      return result
    },
    async indexApi(params) {
      return await this.$api.collection.productApi.index(params)
    },
    async patchApi(value, row, config) {
      const target = row.id
      const column = config.key
      return await this.$api.collection.productApi.patch(target, column, value)
    },
    async deleteApi(target) {
      return await this.$api.collection.productApi.delete(target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.productApi.batch(targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.productApi.batchDelete(targets)
    },
    getListConfig() {
      return listConfig
    },
    cloneProduct(row) {
      this.$apopup.base({
        title: this.$t('action.copy'),
        bodySlot: () => import('modules/product/views/productList/clonePopup.vue'),
        applyCallback: data => this.cloneProductRequest(row.id, data),
        disabledApply: data => {
          if(!data) return true
          if(!this.$helper.validator.required(data.code)) return true
          return false
        },
      })
    },
    async cloneProductRequest(originalProductId, data) {
      try {
        const result = await this.$api.collection.productApi.clone(originalProductId, data)
        this.$snotify.success(null, this.$t('product.copy.successfully'))
        this.listActions.index()
        setTimeout(() => {
          this.gotoDetailPage(result)
        }, 1000)
      } catch (error) {
        console.error(error)
        this.$snotify.error(null, this.$t('product.copy.failure'))
        throw error
      }
    },
    gotoDetailPage(result) {
      this.$apopup.base({
        title: this.$t('product.goto.detail_page'),
        applyCallback: () => {
          this.$router.push({
            name: 'product-update',
            params: { target: result.id },
          })
        }
      })
    },
  },
  computed: {
    productCategoryMaxDepth() {
      return this.$store.getters['base/productCategoryMaxDepth']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
