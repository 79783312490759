import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.product',
      search: 'product.search_keyword',
      filter: {
        status: {
          label: 'data.status',
          type: 'selection',
          options: [false, true],
          optionTextPrefix: 'data.status',
        },
        categories: {
          label: 'data.category',
          type: 'selection',
          optionTextKey: 'name',
          optionValueKey: 'id',
          multiple: true,
          optionMeta: item => ({ depth: item.depth }),
          optionText: (itemName: string, item: any) => this.vm?.$helper.nestedSetNodeNamePrefix(item.name, item.depth),
          options: (listMeta) => {
            if(!listMeta) return []
            if(!Array.isArray(listMeta.categories)) return []
            return listMeta.categories
          },
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => this.vm?.$helper.getPhotoListFirstItem(row.photos),
      },
      table: {
        data: <TableDataType>[
          {
            key: 'provider.name',
            label: 'provider.data.name',
            type: 'text',
          },
          {
            key: 'photos',
            label: 'data.photo',
            type: 'photo',
            photo: (row) => {
              return row.photos
              // return this.vm?.$helper.getProductMasterPhoto(row)
            },
          },
          {
            key: 'name',
            label: 'data.name',
            type: 'text',
            component: () => import('modules/product/views/productList/product.vue'),
          },
          // {
          //   key: 'category',
          //   label: 'data.category',
          //   type: 'text',
          //   component: () => import('modules/product/views/productList/categories.vue'),
          // },
          // {
          //   key: 'price',
          //   label: 'data.price',
          //   type: 'text',
          //   text: row => {
          //     if(row.price_max == row.price_min) {
          //       return this.vm?.$t('product.price.format', { price: row.price_max })
          //     }

          //     return this.vm?.$t('product.price_range.format', {
          //       max: row.price_max,
          //       min: row.price_min,
          //     })
          //   },
          // },
          {
            key: 'status',
            label: 'data.status',
            type: 'switch',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        batch_publish: {
          label: 'product.action.batch_publish',
          column: 'status',
          value: true,
          targetLabel: row => row.name,
        },
        batch_close: {
          label: 'product.action.batch_close',
          column: 'status',
          value: false,
          targetLabel: row => row.name,
        },
        delete: {
          targetLabel: row => row.name,
        },
      },
      metaAction: {
        create: {
          create: (hasWritePermission) => hasWritePermission,
          label: 'action.create',
          linkTarget: '_self',
          type: 'route',
          route: () => ({ name: 'product-create' }),
        },
      },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'product-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        // clone: {
        //   label: 'action.copy',
        //   color: 'green',
        //   buttonProperties: () => ({
        //     class: 'white--text',
        //   }),
        //   // @ts-ignore
        //   callback: row => this.vm.cloneProduct(row),
        // },
        // delete: {
        //   removeTarget: row => row.name,
        // },
      },
    }

  }
}

export default new listConfig()
